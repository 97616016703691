import { Project } from "./Card"

export default function Tools() {
    return (
        <section id='tools-container' className='container p-2 flex flex-col justify-center'>
            <h1 className='text-4xl font-bold p-4 text-center'>Live tools</h1>
            <p className="p-4 w-full text-center">
                These are some tools I have built over the years and deployed for use!
            </p>
            <section id='projects' className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mx-auto'>
                <Project name='bibtexparser' target='https://ramanakumars.com/bibtexparser'>
                    Web parser for converting BibTeX entries to a text format.
                </Project>
                <Project name='MetadataExplorer' target='https://ramanakumars.com/metadata'>
                    Explore metadata associated with images using the Plotly library.
                </Project>
                <Project name='JVH Explorer' target='https://ramanakumars.com/jvhexplorer'>
                    Explore the Jovian Vortex Hunter classification data.
                </Project>
            </section>
        </section>
    )
}
