export default function CV() {
    return (
        <section id='cv' className="w-full mx-auto box-border flex flex-col justify-center items-center">
            <p className='text-center my-2 text-lg'>
                Click <a href="Sankar-CV.pdf" target="_blank">here</a> for a PDF version.
            </p>
            <div className='lg:w-2/3 w-full h-screen my-2 mb-12 box-border'>
                <iframe src={process.env.PUBLIC_URL + '/cv/Sankar-CV.pdf'} width='100%' height='100%'>
                    <p>
                        This browser does not support PDFs. Please use the download option above.
                    </p>
                </iframe>
            </div>
        </section>
    )
}