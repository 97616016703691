import { Link } from "react-router-dom";
import { AiFillGithub } from 'react-icons/ai';

export function Project({ name, target, children }) {
    return (
        <Card>
            <CardHeader name={name} target={target} />
            <CardText>
                {children}
            </CardText>
        </Card>
    )
}

export function GitHubProject({ name, github, children }) {
    return (
        <Card>
            <CardHeader name={name} target={github}>
                <AiFillGithub size='2rem' className='p-0 rounded-full absolute top-0 right-1 m-auto' />
            </CardHeader>
            <CardText>
                {children}
            </CardText>
        </Card>
    )
}

export function Document({ name, link, children }) {
    return (
        <Card>
            <CardHeader name={name} target={link} />
            <CardText>
                {children}
            </CardText>
        </Card>
    )
}

function Card({ children }) {
    return (
        <section className='flex flex-col p-4 mx-auto my-4 w-11/12 bg-tertiary-200 rounded-3xl'>
            {children}
        </section>
    );
}

function CardHeader({ name, target, children }) {
    return (
        <Link to={target} target="_blank" rel="noreferrer" className='hover:underline text-tertiary-900 hover:text-tertiary-700'>
            <div className='w-full flex relative flex-row'>
                <h1 className='w-full text-center text-xl font-semibold '>{name}</h1>
                {children}
            </div>
        </Link>
    )
}

function CardText({ children }) {
    return (
        <div className='p-4 w-full text-base text-justify'>
            {children}
        </div>
    )
}