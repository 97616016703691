import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const papers = [
    'Emma K. Dahl, Naomi Rowe-Gurney, Glenn S. Orton, Shawn R. Brueshaber, Richard G. Cosentino, Csaba Palotai, Ramanakumar Sankar, Kunio M. Sayanagi. "Atmospheric Science Questions for a Uranian Probe". 2024. Space Science Reviews. 10. DOI: <a href="https://doi.org/10.1007/s11214-024-01046-5" target="_blank" rel="noreferrer">10.1007/s11214-024-01046-5</a>',
    'Ramanakumar Sankar, Kameswara Mantha, Lucy Fortson, Helen Spiers, Thomas Pengo, Douglas Mashek, Myat Mo, Mark Sanders, Trace Christensen, Jeffrey Salisbury, Laura Trouille. "TCuPGAN: A novel framework developed for optimizing human-machine interactions in citizen science". 2023. arXiv e-prints. arXiv:2311.14177. DOI: <a href="https://doi.org/10.48550/arXiv.2311.14177" target="_blank" rel="noreferrer">10.48550/arXiv.2311.14177</a>',
    'Csaba Palotai, Shawn Brueshaber, Ramanakumar Sankar, Kunio Sayanagi. "Moist Convection in the Giant Planet Atmospheres". 2022. Remote Sensing. 219. DOI: <a href="https://doi.org/10.3390/rs15010219" target="_blank" rel="noreferrer">10.3390/rs15010219</a>',
    'Kameswara Bharadwaj Mantha, Ramanakumar Sankar, Yuping Zheng, Lucy Fortson, Thomas Pengo, Douglas Mashek, Mark Sanders, Trace Christensen, Jeffrey Salisbury, Laura Trouille, Jarrett E K. Byrnes, Isaac Rosenthal, Henry Houskeeper, Kyle Cavanaugh "From fat droplets to floating forests: cross-domain transfer learning using a PatchGAN-based segmentation model". 2022. CIKM-WS 2022, HIL-DC, DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.48550/arXiv.2211.03937">10.48550/arXiv.2211.03937</a>',
    'Ramanakumar Sankar, Csaba Palotai. "A new convective parameterization applied to Jupiter: Implications for water abundance near the 24\textdegreeN region". 2022. Icarus, 114973 DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.1016/j.icarus.2022.114973">10.1016/j.icarus.2022.114973</a>',
    'Ashley Hughes, Ramanakumar Sankar, Kathryn E. Davis, Csaba Palotai, Dwayne L. Free, Josep Trigo-Rodríguez. "Analysis of the April 13, 2021 bolide off the coast of Florida and Grand Bahama Island". 2022. Meteoritics & Planetary Science, 575-587 DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.1111/maps.13781">10.1111/maps.13781</a>',
    'Ramanakumar Sankar, Chloe Klare, Csaba Palotai. "The aftermath of convective events near Jupiter\'s fastest prograde jet: Implications for clouds, dynamics and vertical wind shear". 2021. Icarus, 114589 DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.1016/j.icarus.2021.114589">10.1016/j.icarus.2021.114589</a>',
    'Nathan Hadland, Ramanakumar Sankar, Raymond Paul LeBeau, Csaba Palotai. "EPIC simulations of Neptune\'s dark spots using an active cloud microphysical model". 2020. Monthly Notices of the RAS, 4760-4768 DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.1093/mnras/staa1799">10.1093/mnras/staa1799</a>',
    'Ramanakumar Sankar, Csaba Palotai, Ricardo Hueso, Marc Delcroix, Ethan Chappel, Agustin Sánchez-Lavega. "Fragmentation modelling of the 2019 August impact on Jupiter". 2020. Monthly Notices of the RAS, 4622-4630 DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.1093/mnras/staa563">10.1093/mnras/staa563</a>',
    'Raymond LeBeau, Kevin Farmer, Ramanakumar Sankar, Nathan Hadland, Csaba Palotai. "A Numerical Investigation of the Berg Feature on Uranus as a Vortex-Driven System". 2020. Atmosphere, 52 DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.3390/atmos11010052">10.3390/atmos11010052</a>',
    'Csaba Palotai, Ramanakumar Sankar, Dwayne L. Free, J. Andreas Howell, Elena Botella, Daniel Batcheldor. "Analysis of the 2016 June 2 bolide event over Arizona". 2019. Monthly Notices of the RAS, 2307-2318 DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.1093/mnras/stz1424">10.1093/mnras/stz1424</a>',
]

const abstracts = [
    'Musset, S., Jol, P., Sankar, R., Lasko, K., Glesener, L., Zhang, Y., Fortson, L., Panesar, N., Fleishman, G., Jeunon, M. and Hurlburt, N. . "Solar Jet Hunter: citizen science to build a catalogue of coronal jets". 2023. 54th Meeting of the Solar Physics Division.',
    'Sankar, R., Wong, M. and Palotai, C. . "How are Jupiter\'s belts and zones affected by deep convection: a parameterized numerical study". 2023. AAS/Division for Planetary Sciences Meeting Abstracts.',
    'Musset, S., Glesener, L., Sankar, R., Fortson, L., Jol, P., Lasko, K., Zhang, Y., Panesar, N., Fleishman, G., Jeunon, M., Hurlburt, N. and Zheng, Y. . "Citizen science and the exploration of solar data". 2023. EGU General Assembly Conference Abstracts.',
    'Mantha, K. B., Sankar, R., Fortson, L., Scarlata, C., Lintott, C., Adams, D., Dickinson, H., Mehta, V., Simmons, B., Smethurst, R., Walmsley, M. and Kurk, S.  "Spatio-Spectroscopic Anomaly Detection in MaNGA IFU-based Data using 3D Deep Learning". 2023. American Astronomical Society Meeting Abstracts, 277.15, 55. ',
    'Sankar, R., Fortson, L., Mantha, K., Johnson, L. and Trouille, L.  "Build citizen science projects: a demonstration of the Zooniverse Project Builder tool". 2022. AAS/Division for Planetary Sciences Meeting Abstracts, 502.02, 54. ',
    'Sankar, R., Brueshaber, S., Fortson, L., Hansen, C., Lintott, C., Mantha, K. and Orton, G.  "Crowd-sourced clouds: Using Citizen Science to study vortices on Jupiter". 2022. AAS/Division for Planetary Sciences Meeting Abstracts, 302.04, 54. ',
    'Lasko, K., Musset, S., Jol, P., Sankar, R., Glesener, L., Fortson, L., Jeunon, M., Zhang, Y., Fleishman, G. D., Panesar, N. and Hurlburt, N. E.  "Solar Jet Hunter: a citizen science investigation of coronal solar jets". 2022. AGU Fall Meeting Abstracts, SH52F-1511, 2022. ',
    'Sankar, R., Brueshaber, S., Fortson, L., Hansen, C. J., Lintott, C., Mantha, K., Orton, G. and Nesmith, C.  "Cataloging Jovian Vortices: A Crowd-Sourced Deep Learning approach". 2022. AGU Fall Meeting Abstracts, P23A-06, 2022. ',
    'Sankar, R., Mantha, K., Fortson, L., Johnson, C. and Trouille, L.  "Machines built by the crowd: how citizen science can enhance machine learning approaches". 2022. The Third Triennial Earth-Sun Summit (TESS, 2022n7i306p05, 54. ',
    'Dahl, E. K., Rowe-Gurney, N., Orton, G. S., Brueshaber, S. R., Cosentino, R. G., Palotai, C., Sankar, R. and Sayanagi, K. M.  "Focus Atmospheric Science Questions for Probing Uranus". 2022. LPI Contributions, 4030, 2686. ',
    'Sankar, R., Palotai, C. and Klare, C.  "Moist convection in the 24degree N jet: studies using a new convective parameterization scheme in the EPIC model". 2021. AAS/Division for Planetary Sciences Meeting Abstracts, 409.02, 53. ',
    'Sankar, R., Palotai, C. and Klare, C.  "Moist convection in the 24degree N jet: modelling the convective plume formation with the EPIC model". 2021. European Planetary Science Congress, EPSC2021-418,  DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.5194/epsc2021-418">10.5194/epsc2021-418</a>',
    'Dahl, E., Brueshaber, S., Cosentino, R., Palotai, C., Rowe-Gurney, N., Sankar, R., Sayanagi, K., Aslam, S., Baines, K., Barth, E., Chanover, N., Fletcher, L., Guerlet, S., Hammel, H., Hofstadter, M., Hyder, A., Leonard, E., Livengood, T. A., Momary, T., Orton, G., de Pater, I., Retherford, K., Sinclair, J., Soderlund, K., Spilker, L., Sromovsky, L. and Wong, M. H.  "Ice Giant Atmospheric Science". 2021. Bulletin of the American Astronomical Society, 264, 53. DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.3847/25c2cfeb.97316a54">10.3847/25c2cfeb.97316a54</a>',
    'Sankar, R., Brueshaber, S., Cosentino, R., Dahl, E., Palotai, C. J., Rowe-Gurney, N. and Sayanagi, K. M.  "Focus questions for Ice Giant atmospheric studies in the next decade". 2020. AGU Fall Meeting Abstracts, P066-0006, 2020. ',
    'Le Beau, R., Farmer, K., Sankar, R., Palotai, C. and Morales-Juberias, R.  "What Drives Dark Spot Drift". 2020. AAS/Division for Planetary Sciences Meeting Abstracts, 317.05, 52. ',
    'Klare, C., Sankar, R. and Palotai, C.  "Modeling the Effect of Water Condensation on Observed Cloud Features Near the 24N Jet on Jupiter". 2020. AAS/Division for Planetary Sciences Meeting Abstracts, 103.03, 52. ',
    'Sankar, R., Klare, C. and Palotai, C.  "Addition of moist convective parameterization to the EPIC model". 2020. AAS/Division for Planetary Sciences Meeting Abstracts, 103.02D, 52. ',
    'Sankar, R., Klare, C. and Palotai, C.  "Moist convection in Jupiter\'s fastest jet". 2020. European Planetary Science Congress, EPSC2020-469,  DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.5194/epsc2020-469">10.5194/epsc2020-469</a>',
    'Hughes, A., Sankar, R., Bamford, E., Gonzalez, M., Economon, S., Palotai, C., Free, D. and Longenbaugh, R.  "Using the Geostationary Lightning Mapper data to calculate orbit and trajectory of bolide events and study fragmentation". 2020. European Planetary Science Congress, EPSC2020-467,  DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.5194/epsc2020-467">10.5194/epsc2020-467</a>',
    'Sankar, R., Klare, C., Palotai, C. J. and Hughes, A.  "Simulation of Jovian moist convection with an active water and ammonia hydrological cycle using the EPIC GCM". 2019. AGU Fall Meeting Abstracts, P11D-3481, 2019. ',
    'Hadland, N., Sankar, R., Klare, C., Palotai, C., Farmer, K. and Lebeau, R.  "EPIC Modeling of Large Scale Dynamical Features of the Gas Giants". 2019. EPSC-DPS Joint Meeting 2019, EPSC-DPS2019-745, 2019. ',
    'Le Beau, R. P., Loiacono, R., Wagner, A., Farmer, K., Palotai, C. J., Sankar, R., Hadland, N. and Nodolski, N.  "Using Numerical Simulation to Consider the Observed Variations in the Behavior and Appearance of Dark Spots on the Ice Giants". 2019. American Astronomical Society Meeting Abstracts #234, 304.02, 234. ',
    'LeBeau, R. P., Farmer, K., Palotai, C. J., Hadland, N., Sankar, R. and Nodolski, N.  "Simulating the Uranian Berg Feature as a Vortex-Driven Cloud". 2018. AGU Fall Meeting Abstracts, P33E-3877, 2018. ',
    'Sankar, R., Hadland, N., Klare, C., Flom, A., Nodolski, N., Palotai, C., LeBeau, R. and Farmer, K.  "Simulations of large scale cloud features on Outer Solar System planets". 2018. AAS/Division for Planetary Sciences Meeting Abstracts #50, 503.08, 50. ',
    'Le Beau, R. P., Farmer, K., Palotai, C., Hadland, N., Sankar, R. and Nodolski, N.  "Simulating Drifting Features in the Atmospheres of the Ice Giants". 2018. AAS/Division for Planetary Sciences Meeting Abstracts #50, 119.11, 50. ',
    'LeBeau, R. P., Koutas, N., Palotai, C. J., Bhure, S., Hadland, N. and Sankar, R.  "Catching the Drift: Simulating Dark Spots and Bright Companions on the Ice Giants". 2017. AGU Fall Meeting Abstracts, P31D-2857, 2017. ',
];


const makeBold = (text, wordsToBold) => {
    return text.replace(new RegExp('(\\b)(' + wordsToBold.join('|') + ')(\\b)','ig'), '$1<strong>$2</strong>$3');
}

export default function Works() {

    const publications = papers.map((paper) => (
        <li>{parse(makeBold(paper, ['Ramanakumar Sankar']))}</li>
    ));

    
    const confabstracts = abstracts.map((abstract) => (
        <li>{parse(makeBold(abstract, ['Sankar, R', 'Ramanakumar Sankar', 'Ramana Sankar']))}</li>
    ));

    return (
        <section id='pubs' className='h-full mx-auto box-border'>
            <nav id='pub-nav' className='h-full p-2 flex flex-row justify-evenly box-border font-bold'>
                <a href='#publications'>
                    Journal Publications
                </a>
                <a href='#conference'>
                    Selected conference abstracts
                </a>
                <a href='#talks'>
                    Invited talks
                </a>
            </nav>
            <section id='publications' className='publications'>
                <a id='publications' name='publications' />
                <h1>Publications</h1>
                <ul>
                    {publications}
                </ul>
            </section>

            <section id='conference' className='publications'>
                <a id='conference' name='conference' />
                <h1>Conference abstracts</h1>
        
                <ul>
                    {confabstracts}            
                </ul>

            </section>

            <section id='talks' className='publications'>
                <a id='talks' name='talks' />
                <h1>Invited Talks</h1>
                <ul>
                    <li>Jovian Vortex Hunter: a citizen science project to study the jovian atmospheric dynamics. Workshop on Juno & Juno-supporting results (DPS/EPSC 2023) <Link to='/slides/Juno2023workshop.pdf'>[slides]</Link></li>
                    <li>Outstanding Challenges in Gas Giant Atmospheric Dynamics Modeling: Review of the Current State-of-the-Art, and Next Steps. NASA Langley Research Center. 2023.</li>
                    <li>Jovian thunderstorms: how they form, and what they tell us about Jupiter's atmosphere. Minnesota Institute for Astrophysics Colloquium series. 2021.</li>
                </ul>
            </section>
        </section>
    )
}