import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Nav from "./Nav";
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from "./Home";
import Tools from "./Tools";
import Works from "./Work";
import CV from "./CV";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <article id='main' className='container box-border mx-auto'>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/works' element={<Works />} />
          <Route path='/cv' element={<CV />} />
          <Route path='/tools' element={<Tools />} />
        </Routes>
      </BrowserRouter>
    </article>
  </React.StrictMode>
);